:root {
  --bs-body-bg: #b7e0eb !important;
}

.row {
  background-color: white !important;
}

.bg-weeley {
  background-color: #009490;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.75) !important;
}

.navbar-dark .navbar-nav .nav-item :hover {
  color: rgba(255, 255, 255, 0.95) !important;
}

.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .nav-link.active {
  color: #fff !important;
}

html, body, #root {
  height: 100vh;
  display: block;
}

#root {
  display: flex;
  flex-direction: column;
}

.p-main {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
  padding-left: 0;
  padding-right: 0;
}

.modal-90w {
  width: 80% !important;
  max-width: none!important;
}